<template>
  <div class="wrap">
    <div class="top-banner">
      <div class="w-1200">
        <div class="title">数据交换平台</div>
        <div class="en">DU-DXP</div>
        <p>解决系统间批量数据交互痛点，提升开发效率，</p>
        <p>降低全行批次执行时间，提升业务感知</p>
      </div>
    </div>
    <div class="main-box w-1200">
      <div class="main-item">
        <div class="title">功能介绍</div>
        <div class="content">
          <div class="function-box">
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-1.png"/>
              <label>数据源管理</label>
              <p>新增数据库，作为数据交换的来源库或者目标库。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-2.png"/>
              <label>系统管理</label>
              <p>包含系统代码、系统简称等信息。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-3.png"/>
              <label>节点管理</label>
              <p>包含节点名称、节点IP信息，可设置/取消执行节点。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-4.png"/>
              <label>数据交换任务</label>
              <p>配置交换任务，选择来源系统/来源库/路径信息，填写表/文件信息（选择文件格式），选择目标系统/目标库/路径信息，填写表/文件信息（选择文件格式）。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-5.png"/>
              <label>交换任务管理</label>
              <p>可发布交换任务到主流调度工具（如：moia、ctm、DS等），包含发布、下线、删除全生命周期操作。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-6.png"/>
              <label>交换记录查询</label>
              <p>包含数据日期、任务名称、来源系统、目标系统、任务状态、开始时间、结束时间、耗时、记录数、文件大小（GB）、日志记录等信息。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-7.png"/>
              <label>任务标识信息接口</label>
              <p>包含：任务名称、数据日期、分发状态、开始时间、结束时间、记录数、文件大小、异常信息。</p>
            </div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-8.png"/>
							<label>数据订阅服务</label>
							<p>可通过API接口，将已生成的文件下载到本地，自行处理。</p>
						</div>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品架构</div>
        <div class="content">
          <img src="~@/assets/images/dxp-architecture.png"/>
        </div>
      </div>
      <div class="main-item">
        <div class="title">核心优势</div>
        <div class="content">
          <div class="core-box">
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-1.png"/>
              <div class="text-box">
                <label>高度配置化</label>
                <p>数据交换平台发布全流程，支持在线配置，发布，零后端管理操作</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-2.png"/>
              <div class="text-box">
                <label>支持线性扩展</label>
                <p>产品基于SpringBoot，可根据需要快速部署到任意数量的节点上，执行数据交换操作，对操作系统，硬件无特殊要求，也不需要统一</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-3.png"/>
              <div class="text-box">
                <label>创新交换模式</label>
                <p>将传统的db2file、文件传输、file2db三步优化为一步，数据交换效率比db2file还快，省去了各业务系统自行开发文件获取，轮询，调度，入库等操作</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-4.png"/>
              <div class="text-box">
                <label>主流数据库支持</label>
                <p>支持基于主流数据库提供数据服务，如：mysql、Oracle等传统数据库，Hive、es、Hbase、Clickhouse等大数据库，Gbase、达梦等国产数据库</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-item">
        <div class="title">应用场景</div>
        <div class="content">
          <div class="scenario-box">
            <div class="scenario-item">
              <label>数据分析，面向企业精细化运营</label>
              <p>汇聚处理各类业务数据，基于中台强大的引擎能力完成复杂的分析与监控工作，灵活响应并满足新需求，实现对用户千人千面的精细化运营</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
            <div class="scenario-item">
              <label>数据治理，为企业数据保驾护航</label>
              <p>针对各种来源的数据，统一数据标准、统一数据资产，同时保障数据安全，从组织和个人角度，促进数据在组织内无障碍地进行交流</p>
              <img src="~@/assets/images/rdc/scenario-2.png"/>
            </div>
            <div class="scenario-item">
              <label>数据应用，助力企业数字化转型</label>
              <p>分析、管理数据，基于硬件和业务数据，完成企业的事前风险预测、事中监控调度和事后问题排查工作，保障生产和供应的可靠性</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #F7F8FA;
}
.top-banner {
  height: 320px;
  background-image: url('~@/assets/images/product/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .title {
    font-size: 40px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding-top: 82px;
  }
  .en {
    font-size: 40px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 14px;
  }
}
.main-box {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #4F5969;
    padding: 40px 0;
  }
  .content {
    img {
      width: 1199px;
    }
  }
  .function-box {
    overflow: hidden;
    .function-item {
      box-sizing: border-box;
      float: left;
      width: 285px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 30px;
      margin-right: 19px;
      >img {
        width: 26px;
      }
      &:nth-child(-n+4) {
        margin-bottom: 19px;
      }
      &:nth-child(4n){
        margin-right: 0;
      }
      label {
        display: block;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #1D2129;
      }
      p {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        line-height: 1.2;
      }
    }
  }
  .core-box {
    overflow: hidden;
    .core-item {
      padding: 40px 28px 40px 30px;
      float: left;
      box-sizing: border-box;
      width: 590px;
      height: 159px;
      background: #FFFFFF;
      border-radius: 5px;
			margin-bottom: 20px;
      >img {
        width: 72px;
      }
      &:nth-child(2n-1) {
        margin-right: 20px;
      }
      img {
        float: left;
      }
      .text-box {
        margin-left: 102px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #1D2129;
        }
        p {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #87909C;
          line-height: 1.2;
        }
      }
    }
  }
  .scenario-box {
    overflow: hidden;
    margin-bottom: 60px;
    .scenario-item {
      padding-top: 30px;
      float: left;
      width: 386px;
      background: #FFFFFF;
      border-radius: 5px;
      >img {
        width: 386px;
      }
      &:not(:last-child){
        margin-right: 19px;
      }
      label {
        font-size: 20px;
        font-weight: 400;
        color: #1D2129;
        padding: 30px 30px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        margin-top: 22px;
        margin-bottom: 30px;
        padding: 0 30px;
        height: 77px;
        line-height: 1.2;
      }
    }
  }
}
</style>
